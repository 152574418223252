@use "color" as *;

* {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;

  app-text-field:focus-within {
    outline: 3px solid map-get($map: $system, $key: "green-light") !important;
    border-radius: 8px;

    &.ng-touched.ng-dirty.ng-invalid {
      outline: 3px solid map-get($map: $system, $key: "red-light") !important;
    }
  }

  label:focus-within .checkmark {
    outline: 3px solid map-get($map: $system, $key: "green-light") !important;
    border-radius: 4px;
  }

  button {
    &:focus-visible {
      outline: 2px solid map-get($map: $system, $key: "green-light") !important;
      border-radius: 4px;
    }
  }
}
